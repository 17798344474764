.landingContainer,
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
}

html,
body {
  padding: 0;
  height: 100%;
  width: 100%;
}
.landingContainer {
  min-height: 100%;
  height: 100vh;
  width: 100%;
  max-width: 100%;

  position: relative;
}
body {
  background: linear-gradient(-90deg, #63abbd, #377cb4, #2e8091, #216a74);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
}
.animatedText {
  color: #fff;
}
#particles canvas {
  position: absolute;
  width: 100%;
  height: 100% !important;
  min-height: 100%;
  z-index: -1;
}
.overParticles {
  position: absolute;
  width: 100%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 25px;
  margin: auto;
  /* min-height: 500px; */
  height: 155px;
}

.typingText {
  font-size: 40px;
  color: #fff;
  font-family: "Bungee", cursive;
}
.overParticles div {
  font-family: "Bungee", cursive;

  overflow: hidden;
  width: 100%;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  text-transform: uppercase;
  padding: 0px;
  opacity: 1;
  height: 50px;
}
#featured {
  position: absolute;
  text-align: center;
  background-color: #fff;
  top: 100vh;
  width: 100%;
  min-height: 100%;
  height: auto;
  border-top: 2px solid #377cb4;
  overflow-x: hidden;
}

#tsparticles {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  cursor: default !important;
}
.logoContainer {
  position: absolute;
  width: 400px;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}
.logoContainer img {
  width: 400px;
}

.landingButton {
  color: #fff;
  background: linear-gradient(-90deg, #216a74, #2e8091, #377cb4, #63abbd);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin: 0 0.5em;
  text-decoration: none;
}
.landingButton:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.13);
  color: #fff;
  cursor: pointer;
}
.buttonContainer {
  position: absolute;
  width: 230px;

  left: 0;
  right: 0;
  bottom: 5em;
  margin: auto;
  z-index: 201;
}
.fade {
  min-height: 100px;
  background-color: rgba(112, 159, 185, 0.1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin: 3em;
  padding: 1em;
  overflow: hidden;
}
.frontEndContainer {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  min-height: 100%;
  position: relative;
}
.featuredImage {
  max-height: 300px;

  position: relative;
  margin-top: auto;
  overflow: hidden;
}
.experience {
  padding: 2em;
}
.experience div {
  margin-top: 0.2 em;
  line-height: 20px;
}
.stat {
  white-space: nowrap;
  display: inline-block;
  font-size: 12px;
  position: relative;
}
#about {
  padding-top: 2em;
  padding-bottom: 2em;
  width: 100%;
  overflow-x: hidden;
}
.numberCounter {
  font-size: 150%;
  color: #377cb4;
}
.aboutImageContainer {
  padding: 1em;
  background-color: rgba(112, 159, 185, 0.1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.aboutImage {
  width: 80%;
}
.aboutText {
  padding: 2em;
  text-align: left;
}
.shell {
  height: 400px;
}
.section {
  margin-top: 4em;
}
#whyHire {
  background-color: rgb(233, 233, 233);
  padding-top: 3em;
  border-bottom: 1px solid #ccc;
}
#featuredProjects {
  padding-top: 3em;
}
#about {
  background-color: rgb(233, 233, 233);
  padding-top: 3em;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.mobileToggle {
  cursor: pointer;
  text-decoration: none;
  font-family: "Unica One", cursive;
}
@media only screen and (max-width: 1200px) {
  .featuredImage {
    height: 250px;
  }
  .fade {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .featuredTitle {
    padding-top: 1em;
    font-size: 24px;
  }
  .buttonContainer {
    bottom: 7em;
  }
  .overParticles div {
    font-size: 35px !important;
    height: 45px !important;
  }
  .typingText {
    font-size: 35px !important;
    line-height: 45px;
  }
  .overParticles {
    width: 100%;
    height: 135px;
  }
}
@media only screen and (max-width: 700px) {
  .featuredImage {
    height: 200px;
  }
  .logoContainer img {
    width: 250px;
    margin-left: auto;
  }
  .logoContainer {
    margin-top: 6em;
    width: 250px;
  }
  .overParticles div {
    font-size: 24px !important;
    height: 34px !important;
  }
  .overParticles {
    width: 100%;
    height: 105px;
  }
  .typingText {
    font-size: 24px !important;
  }
  .featuredImage {
    width: 90%;
    height: auto;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
