.progress-div {
  background-color: rgb(233, 233, 233);
}

.progress {
  background-color: rgb(62, 122, 235);
  height: 10px;

  transition: 1.4s ease;
}
