.contactSubmit {
  width: 100% !important;
  margin-top: 1em;
  background-color: rgba(112, 159, 185, 0.8);
  border: none;
}
.contactMessage {
  margin-top: 1em;
}
.contactDetails {
  margin-top: 2em;
  text-align: left;
}
.contactLogo {
  width: 80%;
  margin-left: 10%;
}
