.user_card {
  min-height: 320px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.loginFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
/* .loginForm {
  margin-top: 3em;
  margin-bottom: 3em;
} */
.brand_logo_container {
  position: relative;
  height: 100px;
  width: 300px;
  top: 5px;
  text-align: center;
}
.brand_logo {
  height: 80px;
  margin: auto 0 auto 0;
}

.login_btn {
  width: 100%;
  background: rgb(41, 41, 41) !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: rgb(41, 41, 41) !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
  height: 100%;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}
.center {
  margin-top: 25vh;
}

@media only screen and (max-width: 600px) {
  .user_card {
    margin-top: 6em;
  }
}
