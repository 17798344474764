.loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 100;
}
.loadingLogo {
  width: 60%;
  height: auto;
  margin: 30vh 20%;
}
.complete {
  background-color: rgb(112, 223, 112);
}
.confirmation {
  position: fixed;
  z-index: 200;
  top: 50%;
  left: 50%;
  padding: 1em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -20%;
}
.confirmationControls button {
  margin: 0.3em;
}

@media only screen and (max-width: 600px) {
  .confirmation {
    position: absolute;
    z-index: 1000;
    top: 30vh;
    left: 20%;
    padding: 1em;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
    margin-left: -20%;
  }
}

/* upload */

.uploadFormContainer {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}
.uploadForm {
  width: 80%;
  margin-left: 10%;
  padding-top: 40px;
  border: 1px dashed rgba(0, 0, 0, 0.171);
  margin-top: 20px;
  margin-bottom: 2em;
}
.uploadForm input[type="radio"] {
  width: 20px;
  height: 30px;
}

/* .uploadForm label {
  font-size: 24px;
} */
.formElement {
  width: 90%;
  margin: 1em 5%;
}
.formElement button {
  margin: 0.5em;
}
.imgToUploadThumbContainer {
  width: 100%;
  display: inline-block;
  margin: 2em 0em;
}
.imgToUploadThumb {
  width: 100px;
  margin: 0.2em;
  display: inline-block;
}
.imgToUploadThumb input {
  width: 100px;
}
.imgToUploadThumb img {
  width: 100px;
  height: 100px;
}
.selectedImage {
  border: 2px solid rgb(196, 138, 138);
}
.notSelectedImage {
  border: 2px solid #ccc;
}
.deleteImage {
  margin-top: 2em;
}

.dashboardHeading {
  font-size: 24px;
}
.activeImage {
  width: auto;
  max-height: 300px;
}
.selectedSiteImage img {
  border-color: green;
}
.controlButton {
  margin: 0.2em;
}
