.redAccent {
  color: #931621;
}
.darkAccent {
  color: #28464b;
}
.mainGreen {
  color: #326771;
}
.mainBG {
  background: #326771;
}
.lightBlue {
  color: #2c8c99;
}
.turquoise {
  color: #42d9c8;
}
p {
  font-family: "Nanum Gothic", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Bungee", cursive;
}
h4,
h5 {
  font-family: "Audiowide", cursive;
}
